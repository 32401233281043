import { Alert } from '#components/Modals/Alert/Alert';
import type { AlertProps as AlertComponentProps } from '#components/Modals/Alert/Alert';

import { PropsWithChildren, useContext } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { FC } from 'react';
import { createContext } from 'react';

type AlertProps = {
  title: string;
  message: string;
  hideIcon?: boolean;
};
type QuestionProps = {
  positiveButtonTitle: string;
  negativeButtonTitle: string;
  positiveCallback: () => void;
  negativeCallback: () => void;
} & AlertProps;

type AlertContextProps = {
  showSuccess: (props: AlertProps, cb?: () => void) => void;
  showError: (props: AlertProps, errorId?: string, cb?: () => void) => void;
  showQuestion: (props: QuestionProps) => void;
};

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

export const AlertWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [alertProps, setAlertProps] = useState<
    AlertComponentProps | undefined
  >();

  const contextRef = useRef<AlertContextProps>({
    showSuccess: ({ message, title }, cb) =>
      setAlertProps({
        message,
        title,
        type: 'SUCCESS',
        open: true,
        onClose: () =>
          setAlertProps((p) => ({ ...p, open: false } as AlertComponentProps)),
        button1: {
          title: 'Ok',
          onClick: () => cb && cb(),
        },
      }),
    showError: ({ message, title }, errorId, cb) =>
      setAlertProps({
        message,
        title,
        type: 'ERROR',
        open: true,
        errorId: errorId,
        onClose: () =>
          setAlertProps((p) => ({ ...p, open: false } as AlertComponentProps)),
        button1: {
          title: 'Ok',
          onClick: () => cb && cb(),
        },
      }),
    showQuestion: ({
      message,
      title,
      negativeCallback,
      positiveCallback,
      negativeButtonTitle,
      positiveButtonTitle,
      hideIcon,
    }) => {
      setAlertProps({
        message,
        title,
        type: 'QUESTION',
        open: true,
        onClose: () =>
          setAlertProps((p) => ({ ...p, open: false } as AlertComponentProps)),
        button1: {
          onClick: () => negativeCallback(),
          title: negativeButtonTitle,
        },
        button2: {
          onClick: () => positiveCallback(),
          title: positiveButtonTitle,
        },
        hideIcon: hideIcon,
      });
    },
  });

  return (
    <AlertContext.Provider value={contextRef.current}>
      {children}
      {alertProps && <Alert {...alertProps} />}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const { showError, showSuccess, showQuestion } = useContext(AlertContext);

  return { showError, showSuccess, showQuestion };
};
