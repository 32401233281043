import { getLikesForUser } from '#lib/firestore';
import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { AssetLike } from '#types/index';

import { PropsWithChildren, useContext, useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { createContext } from 'react';
import { current } from 'tailwindcss/colors';

type LikesContextProps = {
  likes?: AssetLike[];
};

export const LikesContext = createContext<LikesContextProps>({});

export const useIsAssetLiked = (assetId: string) => {
  const { likes } = useContext(LikesContext);
  return likes?.find((l) => l.assetId == assetId);
};

export const useLikedAssets = () => {
  const { likes } = useContext(LikesContext);
  return { likes };
};

export const LikesContextWrapper: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [likesProps, setLikesProps] = useState<LikesContextProps>({});
  const { currentUser } = useFirebaseCurrentUser();

  useEffect(() => {
    if (!currentUser || !currentUser.uid) return;
    return getLikesForUser(currentUser.uid, (l) => {
      setLikesProps({ likes: l });
    });
  }, [currentUser]);

  return (
    <LikesContext.Provider value={{ ...likesProps }}>
      {children}
    </LikesContext.Provider>
  );
};
