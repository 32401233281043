import axios from 'axios';
import {
    Asset,
    EventAsset,
    Creator,
    Invite,
    InviteCapture,
    Event,
    Activity,
    Project,
    MintRequest,
    Notification,
    AssetLike,
    Contract,
    License,
    AnalyticsTopTen,
    NativeAssetRankings,
    User,
    CreatorNotificationPreferences,
    RankingResp,
    NativePolicyStats,
    TVConnect,
    ComingSoonDisplay,
    CollectionImport,
    JunglePolicy,
    NativePolicyTransactions,
    FloorHistory,
    CartItem,
    TrackedTransaction,
    HolderHistory,
    SaleHistory,
    LiveTransaction,
    ProvenanceItem,
    Collective,
    ProjectTrait,
    HolderDistribution,
    Holder,
    FrontPageV2,
    Follow,
    RaffleEntryNotification,
    Raffle,
    LiveMintStats,
    MintReservation,
    RaffleEntry,
    AssetToDrop,
    DropOwner,
    TTPolicyStats,
    TTHolderDistribution,
    MintPhase,
    LiveMintAllowStakeAddresses,
    AssetStats,
    AssetHistory,
    WalletPositionSummary,
    MintPhaseDiscountPolicy,
    OfferRecord,
} from '#types/index';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { OFFER_DATUM_TYPE } from './plutus/DropspotMarket';
import { ClaimRequest } from './hooks/useClaimToken';

import { v4 } from 'uuid';
import { TxInfo } from './hooks/useTxInfo';
import { assert } from 'console';

export const collections = {
    creator: 'Creator',
    follow: 'Follow',
    invite: 'Invite',
    tvConnect: 'TVConnect',
    asset: 'Asset',
    provenance: 'Provenance',
    provenanceItems: 'ProvenanceItems',
    cache: 'Cache',
    policyStats: 'PolicyStats',
    TapTools: 'TapToolStats',
    liveTransactions: 'LiveTransactions',
    raffles: 'Raffle',
    rafflePolicies: 'RafflePolicies',
    issuedTickets: 'IssuedTickets',
    floorHistory: 'FloorHistory',
    oneYear: 'OneYear',
    salesActivity: 'SalesActivity',
    jungle: 'Jungle',
    notification: 'Notification',
    license: 'License',
    holderHistory: 'HolderHistory',
    holderDistribution: 'HolderDistribution',
    traitFloors: 'TraitFloors',
    saleHistory: 'SaleHistory',
    mintRequest: 'MintRequest',
    dropOwner: 'DropOwners',
    redemptionCode: 'RedemptionCodes',
    eventAsset: 'EventAsset',
    assetsToDrop: 'AssetsToDrop',
    project: 'Project',
    collective: 'Collective',
    collectionImport: 'CollectionImport',
    user: 'User',
    event: 'Event',
    assetStats: 'AssetStats',
    mintPhases: 'MintPhases',
    notificationPreferences: 'NotificationPreferences',
    activity: 'Activity',
    offerMetadata: 'OfferMetadata',
    likes: 'Likes',
    cartItem: 'CartItem',
    trackedTransactions: 'TrackedTransactions',
    contract: 'Contract',
    claimRequest: 'ClaimRequest',
    wallet: 'Wallet',
    walletAssets: 'WalletAssets',
    listings: 'Listings',
    dmt: 'dmt',
    maestroTransaction: 'MaestroTransaction',
} as const;

export function getMyOffers(stakeAddress: string, onNext: (data: OfferRecord[]) => void) {
    const db = firebase.firestore();
    return db
        .collection('Offers')
        .where('stakeAddress', '==', stakeAddress)
        .onSnapshot((snap) => {
            onNext(snap.docs.map((doc) => doc.data() as OfferRecord));
        });
}

const chunkSize = 10;
type FN = () => void;

export function getAssetAllOffersForPolicies(policies: string[], onNext: (data: OfferRecord[]) => void) {
    const db = firebase.firestore();

    const subscriptions: FN[] = [];

    for (let i = 0; i < policies.length; i += chunkSize) {
        const chunk = policies.slice(i, i + chunkSize);

        subscriptions.push(
            db.collection('Offers')
                .where('assetPolicy', 'array-contains-any', chunk)
                .onSnapshot((snap) => {
                    onNext(snap.docs.map((doc) => doc.data() as OfferRecord));
                })
        )
    }

    return () => {
        console.log('Cleanup  getAssetOffersForBalance');
        subscriptions.forEach(s => s());
    }
}
export function getAssetOffersForBalance(assets: string[], onNext: (data: OfferRecord[]) => void) {
    const db = firebase.firestore();

    const subscriptions: FN[] = [];

    for (let i = 0; i < assets.length; i += chunkSize) {
        const chunk = assets.slice(i, i + chunkSize);

        subscriptions.push(
            db.collection('Offers')
                .where('asset', 'array-contains-any', chunk)
                .onSnapshot((snap) => {
                    onNext(snap.docs.map((doc) => doc.data() as OfferRecord));
                })
        )
    }

    return () => {
        console.log('Cleanup  getAssetOffersForBalance');
        subscriptions.forEach(s => s());
    }
}

export function getCollectionOffersForBalance(collection: string[], onNext: (data: OfferRecord[]) => void) {
    const db = firebase.firestore();
    const subscriptions: FN[] = [];

    for (let i = 0; i < collection.length; i += chunkSize) {
        const chunk = collection.slice(i, i + chunkSize);

        subscriptions.push(
            db.collection('Offers')
                .where('policy', 'array-contains-any', chunk)
                .onSnapshot((snap) => {
                    onNext(snap.docs.map((doc) => doc.data() as OfferRecord));
                })
        )
    }

    return () => {
        subscriptions.forEach(s => s());
    }
}

export async function getAssetOffers(policy: string, tokenName: string) {
    const db = firebase.firestore();

    const colGroup = await db
        .collectionGroup('Offer')
        .where('policy', '==', policy)
        .where('tokenName', '==', tokenName)
        .get();

    return (
        await Promise.all(colGroup.docs.map((doc) => doc.ref.parent!.parent!.get()))
    ).map((doc) => doc.data());
}

export async function getCollectionOffers(policy: string) {
    const db = firebase.firestore();

    const colGroup = await db
        .collectionGroup('Offer')
        .where('policy', '==', policy)
        .get();

    return (
        await Promise.all(colGroup.docs.map((doc) => doc.ref.parent!.parent!.get()))
    ).map((doc) => doc.data());
}


export const getClaimRequest = (
    claimRequestId: string,
    onNext: (claimRequest: ClaimRequest) => void
) => {
    return firebase
        .firestore()
        .collection(collections.claimRequest)
        .doc(claimRequestId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as ClaimRequest);
        });
};

export const getContract = async (type?: string, limit?: number) => {
    if (!!type) {
        return (
            await firebase
                .firestore()
                .collection(collections.contract)
                .where('type', '==', type)
                .orderBy('version', 'desc')
                .limit(limit || 10)
                .get()
        ).docs.map((doc) => doc.data() as Contract);
    } else {
        return (
            await firebase
                .firestore()
                .collection(collections.contract)
                .orderBy('version', 'desc')
                .limit(limit || 10)
                .get()
        ).docs.map((doc) => doc.data() as Contract);
    }
};

export const getFrontPage = async (id = 'lmDGdA7wyQ4OdQ42X0qu') => {
    const fpDocSnap = await firebase
        .firestore()
        .collection('FrontPage')
        .doc(id)
        .get();

    return fpDocSnap.data() as FrontPageV2;
};

export const getFrontPageComingSoon = async () => {
    const snapshot = await firebase.firestore().collection('ComingSoon').get();
    return snapshot.docs.map((doc) => doc.data() as ComingSoonDisplay);
};



export const getTop100_24HR = async () => {
    const fpDocSnap = await firebase
        .firestore()
        .collection('Trending')
        .doc('Top_100_24H')
        .get();

    const docData = fpDocSnap.data() as RankingResp;
    const tt = docData.ranking as NativeAssetRankings[];
    return tt;
};

export const getTop100_7D = async () => {
    const fpDocSnap = await firebase
        .firestore()
        .collection('Trending')
        .doc('Top_100_7D')
        .get();

    const docData = fpDocSnap.data() as RankingResp;
    const tt = docData.ranking as NativeAssetRankings[];
    return tt;
};

export const getTop100_30D = async () => {
    const fpDocSnap = await firebase
        .firestore()
        .collection('Trending')
        .doc('Top_100_30D')
        .get();

    const docData = fpDocSnap.data() as RankingResp;
    const tt = docData.ranking as NativeAssetRankings[];
    return tt;
};

export const getTop100_All = async () => {
    const fpDocSnap = await firebase
        .firestore()
        .collection('Trending')
        .doc('Top_100_ALL')
        .get();

    const docData = fpDocSnap.data() as RankingResp;
    const tt = docData.ranking as NativeAssetRankings[];
    return tt;
};

export const getCreatorNotificationPreferences = async (
    uid: string
): Promise<CreatorNotificationPreferences> => {
    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot view user profile');
    }

    const result = await db
        .collection(collections.user)
        .doc(uid)
        .collection('NotificationPreferences')
        .doc('Preferences')
        .get();

    return result.data() as CreatorNotificationPreferences;
};

export const getCloudNotificationToken = async (id: string) => {
    const db = firebase.firestore();
    const data = await db
        .doc(`Creator/${id}/NotificationPreferences/Preferences`)
        .get();
    data.data() as CreatorNotificationPreferences;
    if (data.exists) {
        return data.data()?.cloudNotificationToken;
    } else {
        return;
    }
};

export const getNotificationPreferencesListener = (
    creatorId: string,
    onNext: (preferences: CreatorNotificationPreferences) => void
) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        return;
    }

    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(creatorId)
        .collection(collections.notificationPreferences)
        .doc('Preferences')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as CreatorNotificationPreferences);
        });
};

export const createNotificationPreferences = async (
    preferences: CreatorNotificationPreferences
) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create Invites');
    }

    const preferencesToSave: CreatorNotificationPreferences = {
        ...preferences,
        creatorId: currentUser.uid,
        createdBy: currentUser.uid,
        creationDate: new Date().getTime(),
        lastUpdatedBy: currentUser.uid,
        lastUpdateDate: new Date().getTime(),
    };

    await firebase
        .firestore()
        .collection(collections.creator)
        .doc(currentUser.uid)
        .collection(collections.notificationPreferences)
        .doc('Preferences')
        .set(preferencesToSave, { merge: true });

    return preferencesToSave;
};

export const createEvent = async (event: Partial<Event>) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create Invites');
    }

    const fullEvent: Partial<Event> = {
        ...event,
        createdBy: currentUser.uid,
        creationDate: new Date().getTime(),
        lastUpdatedBy: currentUser.uid,
        lastUpdateDate: new Date().getTime(),
    };

    await firebase
        .firestore()
        .collection(collections.event)
        .doc(fullEvent.id)
        .set(fullEvent, { merge: true });

    return fullEvent;
};

export const getEventListener = (
    eventId: string,
    onNext: (creator: Event) => void
) => {
    return firebase
        .firestore()
        .collection(collections.event)
        .doc(eventId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as Event);
        });
};

export const getEventsListener = (onNext: (events: Event[]) => void) => {
    const db = firebase.firestore();

    return (
        db
            .collection(collections.event)
            .orderBy('creationDate', 'desc')
            // .where('public', '==', true)
            .onSnapshot((snap) => {
                if (!snap) {
                    return;
                }

                onNext(snap.docs.map((doc) => doc.data() as Event));
            })
    );
};

export const getAssetStatsListener = (
    assetId: string,
    tokenName: string,
    policy: string,
    onNext: (assetStats: AssetStats) => void
) => {
    const db = firebase.firestore();

    return db
        .collection(collections.asset)
        .doc(assetId)
        .collection(collections.assetStats)
        .doc('Summary')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }

            onNext(snap.data() as AssetStats);
        });
};

export const getAssetHistoryListener = (
    assetId: string,
    tokenName: string,
    policy: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onNext: (assetStats: any) => void
) => {
    const db = firebase.firestore();

    return db
        .collection(collections.asset)
        .doc(assetId)
        .collection(collections.assetStats)
        .doc('History')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }

            onNext(snap.data());
        });
};

export const getAssetRef = async (ref: string) => {
    const db = firebase.firestore();
    const data = await db.doc(ref).get();
    return data.data() as Asset;
};

export const getEventAsset = async (eid: string, eaid: string) => {
    const db = firebase.firestore();
    const result = await db
        .collection(collections.event)
        .doc(eid)
        .collection(collections.eventAsset)
        .doc(eaid)
        .get();
    return result.data() as EventAsset;
};

export const getEventAssetById = async (eaid: string) => {
    const db = firebase.firestore();
    const result = await db
        .collectionGroup(collections.eventAsset)
        .where('id', '==', eaid)
        .limit(1)
        .get();

    if (result.size === 0) {
        return;
    }
    return result.docs[0].data() as EventAsset;
};

export const getEventAssetsListener = (
    eventId: string,
    onNext: (events: EventAsset[]) => void
) => {
    const db = firebase.firestore();

    return (
        db
            .collection(collections.event)
            .doc(eventId)
            .collection(collections.eventAsset)
            .orderBy('creationDate', 'desc')
            // .where('public', '==', true)
            .onSnapshot((snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as EventAsset));
            })
    );
};

export const getEvent = async (id: string) => {
    const db = firebase.firestore();
    const result = await db.collection(collections.project).doc(id).get();
    return result.data() as Project;
};

export const getEvents = async () => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.event)
        .orderBy('creationDate', 'desc')
        //.where('public', '==', true)
        .get();

    return result.docs.map((doc) => doc.data() as Event);
};

export const getAllEventAssets = async () => {
    const db = firebase.firestore();

    const result = await db
        .collectionGroup(collections.eventAsset)
        //.where('public', '==', true)
        .get();

    return result.docs.map((doc) => doc.data() as EventAsset);
};

export const getAllAssets = async () => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.asset)
        // .where('projectId', '==', true)
        .get();

    return result.docs.map((doc) => doc.data() as Asset);
};

export const getProjectListingsCount = async (id: string) => {
    const db = firebase.firestore();
    const collectionRef = db.collection(collections.asset);
    const query = collectionRef
        .where('projectId', '==', id)
        .where('marketStatus', '==', 'LISTED')
        .limit(11);
    const snapshot = await query.get();

    return snapshot.docs.length || 0;
};

export const getProject = async (id: string) => {
    const db = firebase.firestore();
    const result = await db.collection(collections.project).doc(id).get();
    return result.data() as Project;
};

export const getProjectByPolicy = async (policy: string) => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('policy', '==', policy)
        .limit(1)
        .get();

    const docs = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Project);

    if (docs) {
        return docs[0];
    } else {
        return;
    }
};

export const getCollectiveByShortName = async (shortName: string) => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.collective)
        .orderBy('creationDate', 'desc')
        .where('shortName', '==', shortName)
        .limit(1)
        .get();

    const docs = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Collective);

    if (docs) {
        return docs[0];
    } else {
        return;
    }
};

export const getCollectionsForCollective = async (shortName: string) => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .orderBy('creationDate', 'desc')
        .where('collectiveCampaign', '==', shortName)
        .where('status', '==', 'Minted')
        .get();

    const docs = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Project);

    if (docs) {
        return docs;
    } else {
        return;
    }
};

export const getProjectByShortName = async (shortName: string) => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .orderBy('creationDate', 'desc')
        .where('tokenPrefix', '==', shortName)
        .limit(1)
        .get();

    const docs = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Project);

    if (docs) {
        return docs[0];
    } else {
        return;
    }
};

export const getAllProjectAssets = async (
    projectId: string,
    limit?: number
) => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.asset)
        .where('projectId', '==', projectId)
        .orderBy('creationDate', 'asc')
        .limit(limit || 100)
        .get();

    return result.docs.map((doc) => {
        const cleanedData = doc.data();
        delete cleanedData['minted_date'];
        return cleanedData as Asset;
    });
};

export const updateWalletAssetWithTransaction = async (
    assetId: string,
    txHash: string,
    userId: string
): Promise<string> => {
    const db = firebase.firestore();
    db.collection(collections.creator)
        .doc(userId)
        .collection(collections.wallet)
        .doc('Main')
        .collection(collections.walletAssets)
        .doc(assetId)
        .update({ txInProgress: true });
    return txHash;
};

export const getWalletParsedTransactions = async (
    address: string
): Promise<TxInfo[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.creator)
        .doc(address)
        .collection('TransactionsParsed')
        .get();

    return result.docs.map((doc) => doc.data() as TxInfo);
};

export const getWalletPositionSummaryListener = (
    userId: string,
    onNext: (assets: WalletPositionSummary) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('TapToolStats')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as WalletPositionSummary);
        });
};

export const getWalletNativeAssets = async (
    userId: string
): Promise<Asset[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('WalletAssets')
        .get();

    return result.docs.map((doc) => doc.data() as Asset);
};

export const getWalletNativeAssetsListener = (
    userId: string,
    onNext: (assets: Asset[]) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('WalletAssets')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Asset));
        });
};

export const getWalletSyncInsertsListener = (
    userId: string,
    onNext: (inserts: number) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('WalletAssetsImporting')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.length);
        });
};

export const getWalletExternalListingSyncInsertsListener = (
    userId: string,
    onNext: (inserts: number) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('ExternalListingsTracking')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.length);
        });
};

export const deleteExternalListingSyncInsert = async (
    assetId: string,
    userId: string
) => {
    console.log('IDS:', assetId, userId);
    const db = firebase.firestore();
    await db
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('ExternalListings')
        .doc(assetId)
        .delete();
}

export const getWalletListener = (
    userId: string,
    onNext: (main: {
        lastListingRefresh: number;
        status: string;
        syncEndTime: number;
        syncStartTime: number;
        totalCount: number;
    }) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(
                snap.data() as {
                    lastListingRefresh: number;
                    status: string;
                    syncEndTime: number;
                    syncStartTime: number;
                    totalCount: number;
                }
            );
        });
};
export const getWalletListingsListener = (
    userId: string,
    onNext: (assets: Asset[]) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('Listings')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Asset));
        });
};

export const getWalletExternalListingsListener = (
    userId: string,
    onNext: (assets: Asset[]) => void
) => {
    console.log('ID:', userId);
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(userId)
        .collection('Wallet')
        .doc('Main')
        .collection('ExternalListings')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Asset));
        });
};

export const getAssetProvenanceListener = (
    assetId: string,
    onNext: (projects: ProvenanceItem[]) => void
) => {
    console.log('ID:', assetId);
    return firebase
        .firestore()
        .collection(collections.asset)
        .doc(assetId)
        .collection(collections.provenance)
        .doc(collections.cache)
        .collection(collections.provenanceItems)
        .orderBy('timestamp', 'desc')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as ProvenanceItem));
        });
};

type MaestroTransaction = {
    tx_hash: string;
    state: string;
    timestamp: string;
    block_number: number;
};

export const getMaestroTransaction = (
    txHash: string,
    onNext: (data: MaestroTransaction) => void
) => {
    const db = firebase.firestore();

    return db
        .collection(collections.maestroTransaction)
        .doc(txHash)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as MaestroTransaction);
        });
};

export const getMintPhases = async (policy: string): Promise<MintPhase[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.project)
        .doc(policy)
        .collection(collections.mintPhases)
        .orderBy('startDate', 'asc')
        .get();

    return result.docs.map((doc) => doc.data() as MintPhase);
};

export const getMintPhaseDiscounts = async (
    policy: string,
    mintPhaseId: string
): Promise<MintPhaseDiscountPolicy[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.project)
        .doc(policy)
        .collection(collections.mintPhases)
        .doc(mintPhaseId)
        .collection('DiscountPolicies')
        .orderBy('mintPrice', 'desc')
        .get();

    return result.docs.map((doc) => doc.data() as MintPhaseDiscountPolicy);
};

export const getMintPhaseAllowLists = async (
    policy: string,
    mintPhaseId: string
): Promise<LiveMintAllowStakeAddresses[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.project)
        .doc(policy)
        .collection(collections.mintPhases)
        .doc(mintPhaseId)
        .collection('AllowStakeAddresses')
        .orderBy('count', 'desc')
        .get();

    return result.docs.map((doc) => doc.data() as LiveMintAllowStakeAddresses);
};

export const getLiveTransactionsAfterDate = async (
    policy: string
): Promise<LiveTransaction[]> => {
    const db = firebase.firestore();

    const result = await db
        .collectionGroup(collections.liveTransactions)
        .where('policy', '==', policy)
        .where('createdDate', '>', new Date().getTime() - 1000 * 60 * 60 * 24)
        .orderBy('createdDate', 'desc')
        .get();

    return result.docs.map((doc) => doc.data() as LiveTransaction);
};

export const getLiveTransactions = async (
    policy: string
): Promise<LiveTransaction[]> => {
    const db = firebase.firestore();

    const result = await db
        .collectionGroup(collections.liveTransactions)
        .where('policy', '==', policy)
        .orderBy('createdDate', 'desc')
        .limit(5)
        .get();

    return result.docs.map((doc) => doc.data() as LiveTransaction);
};

export const getLiveTransactionsListener = (
    policy: string,
    limit: number,
    onNext: (projects: LiveTransaction[]) => void
) => {
    console.log('ID:', policy);
    return firebase
        .firestore()
        .collectionGroup(collections.liveTransactions)
        .where('policy', '==', policy)
        .orderBy('createdDate', 'desc')
        .limit(limit)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as LiveTransaction));
        });
};

export const getLiveTransactionsListenerByType = (
    type: 'sale' | 'listed' | 'all',
    limit: number,
    onNext: (projects: LiveTransaction[]) => void
) => {
    let query = firebase
        .firestore()
        .collectionGroup(collections.liveTransactions)
        .orderBy('createdDate', 'desc');

    if (type === 'all') {
        query = query.where('type', 'in', ['listed', 'sale']);
    } else {
        query = query.where('type', '==', type);
    }

    return query.limit(limit).onSnapshot((snap) => {
        if (!snap) {
            return;
        }
        onNext(snap.docs.map((doc) => doc.data() as LiveTransaction));
    });
};

export const getAllLiveTransactionsListener = (
    onNext: (projects: LiveTransaction[]) => void
) => {
    return firebase
        .firestore()
        .collectionGroup(collections.liveTransactions)
        .orderBy('createdDate', 'desc')
        .limit(100)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as LiveTransaction));
        });
};

export const getListedProjectAssets = async (
    projectId: string,
    limit?: number,
    desc?: string
): Promise<Asset[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.asset)
        .where('projectId', '==', projectId)
        .where('price', '>', 0)
        .where('marketStatus', '==', 'LISTED')
        .orderBy('price', desc ? 'desc' : 'asc')
        .limit(limit || 1000)
        .get();

    return result.docs.map((doc) => doc.data() as Asset);
};

export const getListedProjectFloorHistory = async (
    projectId: string
): Promise<FloorHistory> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.policyStats)
        .doc(projectId)
        .collection(collections.floorHistory)
        .doc(collections.oneYear)
        .get();

    return result.data() as FloorHistory;
};

export const getProjects = async (limit?: number): Promise<Project[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('Status', '==', 'Minted')
        .orderBy('creationDate', 'desc')
        .limit(limit || 1000)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Project);
};

export const getSuperCubProjects = async (
    limit?: number
): Promise<Project[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('superCubeActive', '==', true)
        // .orderBy('creationDate', 'desc')
        .limit(limit || 20)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Project);
};

export const getPartnerProjects = async (
    limit?: number
): Promise<Project[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('partnerProject', '==', true)
        // .orderBy('creationDate', 'desc')
        .limit(limit || 20)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Project);
};

export interface FirestoreQueryItem {
    fieldPath: string;
    opStr: firebase.firestore.WhereFilterOp; //< | <= | == | >= | > | array-contains | in | array-contains-any | not-in
    value: unknown;
}

export interface FirestoreQuery {
    orderBy: string;
    desc: boolean;
    whereClauses?: FirestoreQueryItem[];
}

export interface ProjectsResult {
    projects: Project[];
    lastKey: string;
}

export interface AssetResult {
    assets: Asset[];
    lastKey: string;
}

export const getAssetsWithDynamicMapping = async (
    query?: FirestoreQuery,
    limit?: number
): Promise<AssetResult> => {
    const db = firebase.firestore();

    let assetsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
        db.collection(collections.asset);

    // Dynamically add where clauses to the query
    if (query && query.whereClauses) {
        query.whereClauses.forEach((whereClause) => {
            assetsRef = assetsRef.where(
                whereClause.fieldPath,
                whereClause.opStr,
                whereClause.value
            ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
        });
    }
    if (query && query.orderBy) {
        assetsRef = assetsRef.orderBy(
            query.orderBy,
            query.desc ? 'desc' : 'asc'
        ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    }
    const querySnap = await assetsRef.limit(limit || 200).get();

    let lastKey = '';
    querySnap.forEach((doc) => {
        lastKey = doc.data().creationDate as string;
    });

    const assets = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Asset);
    return { assets, lastKey };
};

export const getAssetsForNextBatch = async (
    key: number,
    limit: number,
    query: FirestoreQuery
): Promise<AssetResult> => {
    const db = firebase.firestore();

    let assetsRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
        db.collection(collections.asset);

    // Dynamically add where clauses to the query
    if (query && query.whereClauses) {
        query.whereClauses.forEach((whereClause) => {
            assetsRef = assetsRef.where(
                whereClause.fieldPath,
                whereClause.opStr,
                whereClause.value
            ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
        });
    }

    if (query && query.orderBy) {
        assetsRef = assetsRef.orderBy(
            query.orderBy,
            query.desc ? 'desc' : 'asc'
        ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    }

    const querySnap = await assetsRef.limit(limit).startAfter(key).get();

    let lastKey = '';
    querySnap.forEach((doc) => {
        lastKey = doc.data().creationDate;
    });

    const assets = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Asset);
    return { assets, lastKey };
};

export const getProjectWithDynamicMapping = async (
    query?: FirestoreQuery,
    limit?: number
): Promise<ProjectsResult> => {
    const db = firebase.firestore();

    let collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
        db.collection(collections.project);

    // Dynamically add where clauses to the query
    if (query && query.whereClauses) {
        query.whereClauses.forEach((whereClause) => {
            collectionRef = collectionRef.where(
                whereClause.fieldPath,
                whereClause.opStr,
                whereClause.value
            ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
        });
    }
    if (query) {
        console.log('ADDING ORDER:', query.desc ? 'desc' : 'asc');
        collectionRef = collectionRef.orderBy(
            query.orderBy,
            query.desc ? 'desc' : 'asc'
        ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    }
    const querySnap = await collectionRef.limit(limit || 200).get();

    let lastKey = '';
    querySnap.forEach((doc) => {
        lastKey = doc.data().creationDate as string;
    });

    const projects = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Project);
    return { projects, lastKey };
};

export const getProjectsWithLastKey = async (
    query?: FirestoreQuery,
    limit?: number
): Promise<ProjectsResult> => {
    const db = firebase.firestore();
    let collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
        db.collection(collections.project);

    // Dynamically add where clauses to the query
    if (query && query.whereClauses) {
        query.whereClauses.forEach((whereClause) => {
            collectionRef = collectionRef.where(
                whereClause.fieldPath,
                whereClause.opStr,
                whereClause.value
            ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
        });
    }

    if (query) {
        collectionRef = collectionRef.orderBy(
            query.orderBy,
            query.desc ? 'desc' : 'asc'
        ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    }

    const querySnap = await collectionRef.limit(limit || 200).get();

    let lastKey = '';
    querySnap.forEach((doc) => {
        lastKey = doc.data().creationDate as string;
    });

    const projects = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Project);
    return { projects, lastKey };
};

export const getProjectsForNextBatch = async (
    key: string | number,
    limit: number,
    query?: FirestoreQuery
): Promise<ProjectsResult> => {
    const db = firebase.firestore();

    let collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =
        db.collection(collections.project);

    // Dynamically add where clauses to the query
    if (query && query.whereClauses) {
        query.whereClauses.forEach((whereClause) => {
            collectionRef = collectionRef.where(
                whereClause.fieldPath,
                whereClause.opStr,
                whereClause.value
            ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
        });
    }

    if (query) {
        collectionRef = collectionRef.orderBy(
            query.orderBy,
            query.desc ? 'desc' : 'asc'
        ) as firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    }

    const querySnap = await collectionRef.limit(limit).startAfter(key).get();

    let lastKey = '';
    querySnap.forEach((doc) => {
        lastKey = doc.data().creationDate;
    });

    const projects = querySnap.docs.map((doc) => doc.data() as Project);
    return { projects, lastKey };
};

export const getAllCollectives = async (
    limit?: number
): Promise<Collective[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.collective)
        .orderBy('creationDate', 'desc')
        .limit(limit || 10)
        .get();

    return querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Collective);
};

export const getRelatedProjects = async (ids: string[]): Promise<Project[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('id', 'in', ids)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Project);
};

export const getPublicProjects = async (limit?: number): Promise<Project[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('status', '==', 'Minted')
        .orderBy('creationDate', 'desc')
        .limit(limit || 100)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Project);
};
export const getCreatorProjects: (creatorId: string) => Promise<Project[]> =
    async (creatorId: string): Promise<Project[]> => {
        const db = firebase.firestore();

        const querySnap = await db
            .collection(collections.project)
            .orderBy('creationDate', 'desc')
            .where('createdBy', '==', creatorId)
            .get();

        return querySnap.docs
            .map((doc) => doc.data())
            .map((data) => data as Project);
    };

export const getMintedCreatorProjects: (
    creatorId: string
) => Promise<Project[]> = async (creatorId: string): Promise<Project[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.project)
        .where('createdBy', '==', creatorId)
        .where('status', '==', 'Minted')
        .orderBy('creationDate', 'desc')
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Project);
};

export const getCreatorProjectsListener = (
    uid: string,
    onNext: (projects: Project[]) => void
) => {
    console.log('ID:', uid);
    return firebase
        .firestore()
        .collection(collections.project)
        .orderBy('creationDate', 'desc')
        .where('createdBy', '==', uid)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Project));
        });
};

export const getSharedCreatorProjects = (
    uid: string,
    onNext: (projects: Project[]) => void
) => {
    console.log('ID:', uid);
    return firebase
        .firestore()
        .collection(collections.project)
        .orderBy('creationDate', 'desc')
        .where('owners', 'array-contains', uid)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Project));
        });
};

export const getCreatorAssetsListener = (
    uid: string,
    onNext: (projects: Asset[]) => void
) => {
    console.log('ID:', uid);
    return firebase
        .firestore()
        .collection(collections.asset)
        .orderBy('creationDate', 'desc')
        .where('createdBy', '==', uid)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Asset));
        });
};

export const getCreatorMintedProjectsListener = (
    uid: string,
    onNext: (projects: Project[]) => void
) => {
    console.log('ID:', uid);
    return firebase
        .firestore()
        .collection(collections.project)
        .orderBy('creationDate', 'desc')
        .where('createdBy', '==', uid)
        .where('status', '==', 'Minted')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Project));
        });
};

export const addAssetToCart = async (asset: Asset, userId: string) => {
    const db = firebase.firestore();
    const docPath = `/Creator/${userId}/${collections.cartItem}/${asset.id}`;
    return await db
        .doc(docPath)
        .set(
            { createdDate: new Date().getTime(), asset: asset, userId: userId },
            { merge: true }
        );
};

export const removeItemFromCart = async (assetId: string, userId: string) => {
    const db = firebase.firestore();
    const docPath = `/Creator/${userId}/${collections.cartItem}/${assetId}`;
    return await db.doc(docPath).delete();
};

export const getCartItemsListener = (
    uid: string,
    onNext: (cartItems: CartItem[]) => void
) => {
    console.log('CART ITEM LISTENER: ID:', uid);

    const collectionPath = `/Creator/${uid}/${collections.cartItem}`;
    return firebase
        .firestore()
        .collection(collectionPath)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as CartItem));
        });
};

// export const updateWalletListingToComplete = async (
//   userId: string,
//   assetId: string
// ) => {
//   const db = firebase.firestore();
//   const docPath = `/Creator/${userId}/${collections.wallet}/Main/Listings/${assetId}`;
//   console.log('WLUPDATE>CHECK FOR: ', docPath);
//   const doc = await db.doc(docPath).get();
//   if (doc.exists) {
//     console.log('WLUPDATE> FOUND WALLET LISTING TO TO.');
//     return await db.doc(docPath).set({ txInProgress: false }, { merge: true });
//   } else {
//     console.log('WLUPDATE>WALLET LISTING NOT FOUND.');
//     return;
//   }
// };

export const updateWalletListingToComplete = async (
    userId: string,
    assetId: string
) => {
    try {
        const response = await axios.post('/api/transactionActivity/updateWL', {
            userId,
            assetId,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating wallet listing:', error);
        throw error;
    }
};

// export const updateTrackedTransaction = async (
//   userId: string,
//   txId: string,
//   status: string
// ) => {
//   const db = firebase.firestore();

//   return (
//     await db
//       .collection(collections.creator)
//       .doc(userId)
//       .collection(collections.trackedTransactions)
//       .where('txId', '==', txId)
//       .get()
//   ).docs.map((d) =>
//     d.ref.update({ lastStatusUpdateDate: new Date().getTime(), status: status })
//   );
// };

export const updateTrackedTransaction = async (
    userId: string,
    txId: string,
    status: string
) => {
    try {
        const response = await axios.post('/api/transactionActivity/updateTx', {
            userId,
            txId,
            status,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating tracked transaction:', error);
        throw error;
    }
};

export const getTrackedTransactionsListener = (
    uid: string,
    onNext: (trackedTransactions: TrackedTransaction[]) => void
) => {
    const collectionPath = `/Creator/${uid}/${collections.trackedTransactions}`;
    return firebase
        .firestore()
        .collection(collectionPath)
        .orderBy('createdDate', 'desc')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as TrackedTransaction));
        });
};

export const getCreatorLikedAssetsListener = (
    uid: string,
    onNext: (projects: AssetLike[]) => void
) => {
    console.log('ID:', uid);
    return firebase
        .firestore()
        .collectionGroup(collections.likes)
        .where('userId', '==', uid)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as AssetLike));
        });
};

export const getCreatorLikedAssets = async (
    uid: string
): Promise<AssetLike[]> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.likes)
        .where('userId', '==', uid)
        .get();

    return result.docs.map((doc) => doc.data()).map((data) => data as AssetLike);
};

export const getProjectTraits = async (
    projectId: string
): Promise<ProjectTrait> => {
    const db = firebase.firestore();

    const result = await db
        .collection(collections.project)
        .doc(projectId)
        .collection('Traits')
        .doc('TraitDictionary')
        .get();

    return result.data() as ProjectTrait;
};

export const getProjectsListener = (onNext: (projects: Project[]) => void) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .orderBy('creationDate', 'desc')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as Project));
        });
};

export const getProjectListener = (
    projectId: string,
    onNext: (projects: Project) => void
) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as Project);
        });
};

export const getCollectionImportListener = (
    filter: string,
    onNext: (projects: CollectionImport[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.collectionImport)
        .where('status', '==', filter)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as CollectionImport));
        });
};

export const getCollectionImportByPolicy = async (policy: string) => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.collectionImport)
        .doc(policy)
        .get();

    return querySnap.data() as CollectionImport;
};

export const getCreatorCollectionImports = (
    creatorId: string,
    onNext: (assets: CollectionImport[]) => void
) => {
    if (!creatorId) {
        return;
    }
    return firebase
        .firestore()
        .collection(collections.collectionImport)
        .where('createdBy', '==', creatorId)
        .where('status', '!=', 'Published')
        .orderBy('status')
        .orderBy('creationDate', 'desc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as CollectionImport));
            },
            (err) => console.error(err)
        );
};

export const getCollectionImportById = async (id: string) => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.collectionImport)
        .doc(id)
        .get();

    return querySnap.data() as CollectionImport;
};

export const deleteCollectionImport = (ciid: string) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users delete project');
    }

    const db = firebase.firestore();

    return db.collection(collections.collectionImport).doc(ciid).delete();
};

export const createOrSaveFollow = async (follow: Follow) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || !currentUser?.uid) {
        throw new Error('Unauthenticated users cannot create user profile');
    }
    await firebase
        .firestore()
        .collection(collections.creator)
        .doc(currentUser.uid)
        .collection(collections.follow)
        .doc(follow.id)
        .set(follow, { merge: true });

    return follow;
};

export const deleteFollow = (followId: string) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users delete project');
    }

    const db = firebase.firestore();

    return db
        .collection(collections.creator)
        .doc(currentUser.uid)
        .collection(collections.follow)
        .doc(followId)
        .delete();
};

export const getUserFollowsListener = (onNext: (follows: Follow[]) => void) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || !currentUser?.uid) {
        throw new Error('Unauthenticated users cannot create user profile');
    }
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(currentUser.uid)
        .collection(collections.follow)
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as Follow));
            },
            (err) => console.error(err)
        );
};

export const createOrSaveCollectionImport = async (
    collectionImport: CollectionImport
) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create user profile');
    }
    console.log('collectionImport', collectionImport);
    await firebase
        .firestore()
        .collection(collections.collectionImport)
        .doc(collectionImport.id)
        .set(collectionImport, { merge: true });

    return collectionImport as CollectionImport;
};

export const createOrSaveUser = async (user: User) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create user profile');
    }
    await firebase
        .firestore()
        .collection(collections.user)
        .doc(currentUser.uid)
        .set(user, { merge: true });

    return user as User;
};

export const getUser = async (uid: string): Promise<User> => {
    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot view user profile');
    }

    const result = await db.collection(collections.user).doc(uid).get();

    return result.data() as User;
};

export const getUserListener = (onNext: (user: User) => void) => {
    const currentUser = firebase.auth().currentUser;
    return firebase
        .firestore()
        .collection(collections.user)
        .doc(currentUser?.uid)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as User);
        });
};

export const deleteProject = (projectId: string) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users delete project');
    }

    const db = firebase.firestore();

    return db.collection(collections.project).doc(projectId).delete();
};

export const createProject = async (project: Partial<Project>) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create project');
    }

    const fullProject = { ...project };

    if (!fullProject.creationDate) {
        fullProject.creationDate = new Date().getTime();
        fullProject.createdBy = currentUser.uid;
    }

    fullProject.lastUpdateDate = new Date().getTime();
    fullProject.lastUpdatedBy = currentUser.uid;

    await firebase
        .firestore()
        .collection(collections.project)
        .doc(fullProject.id)
        .set(fullProject, { merge: true });

    return fullProject as Project;
};

export const deleteAsset = (assetId: string) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Need to be authenticated to delete.');
    }

    const db = firebase.firestore();

    return db.collection(collections.asset).doc(assetId).delete();
};

export const clearTxHash = (id: string) => {
    const db = firebase.firestore();
    console.log('Clear hash id: ', id);
    return db
        .collection(collections.asset)
        .doc(id)
        .update({ txHash: firebase.firestore.FieldValue.delete() });
};

export const setTxHash = (assetId: string, txHash: string) => {
    const db = firebase.firestore();
    return db
        .collection(collections.asset)
        .doc(assetId)
        .set({ txHash }, { merge: true });
};

export async function getAssetFromPolicyAndTokenName(
    policy: string,
    tokenName: string
) {
    const db = firebase.firestore();
    const result = await db
        .collection(collections.asset)
        .where('policy', '==', policy)
        .where('tokenName', '==', tokenName)
        .limit(1)
        .get();

    if (result.docs.length === 0) return;

    return result.docs[0].data() as Asset;
}

export const getAsset = async (id: string): Promise<Asset> => {
    const db = firebase.firestore();
    const result = await db.collection(collections.asset).doc(id).get();
    return result.data() as Asset;
};

export const getAssets = async (limit?: number): Promise<Asset[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.asset)
        .orderBy('creationDate', 'desc')
        .limit(limit || 100)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Asset);
};

export const getAssetListener = (
    assetId: string,
    onNext: (asset: Asset) => void
) => {
    return firebase
        .firestore()
        .collection(collections.asset)
        .doc(assetId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as Asset);
        });
};

export const getLiveMintStatsListener = (
    projectId: string,
    onNext: (liveMintStats: LiveMintStats) => void
) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('LiveMintStats')
        .doc('Summary')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as LiveMintStats);
        });
};

export const getLiveMintReservationsAdminListener = (
    projectId: string,
    onNext: (liveMintReservations: MintReservation[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('MintReservation')
        .orderBy('createdDate', 'desc')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as MintReservation));
        });
};

export const getLiveMintRedemptionsListener = (
    projectId: string,
    userId: string,
    onNext: (liveMintRedemptions: MintReservation[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('MintRedemption')
        .where('status', 'not-in', ['Expired', 'Minted'])
        .where('createdBy', '==', userId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as MintReservation));
        });
};

export const getLiveMintReservationsListener = (
    projectId: string,
    userId: string,
    onNext: (liveMintReservations: MintReservation[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('MintReservation')
        .where('status', 'not-in', ['Expired', 'Minted'])
        .where('createdBy', '==', userId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as MintReservation));
        });
};

export const getAllLiveMintReservationsListener = (
    projectId: string,
    userId: string,
    onNext: (liveMintReservations: MintReservation[]) => void
) => {
    return (
        firebase
            .firestore()
            .collection(collections.project)
            .doc(projectId)
            .collection('MintReservation')
            .orderBy('createdDate', 'desc')
            // .where('status', 'not-in', ['Expired', 'Minted'])
            .where('createdBy', '==', userId)
            .onSnapshot((snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as MintReservation));
            })
    );
};

export const getAllLiveMintRedemptionsListener = (
    projectId: string,
    userId: string,
    onNext: (liveMintReservations: MintReservation[]) => void
) => {
    return (
        firebase
            .firestore()
            .collection(collections.project)
            .doc(projectId)
            .collection('MintRedemption')
            .orderBy('createdDate', 'desc')
            // .where('status', 'not-in', ['Expired', 'Minted'])
            .where('createdBy', '==', userId)
            .onSnapshot((snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as MintReservation));
            })
    );
};

export const getLiveMintReservationListener = (
    projectId: string,
    mintReservationId: string,
    onNext: (liveMintReservations: MintReservation) => void
) => {
    console.log('MINT RESERVATION ID:', mintReservationId);
    console.log('PROJECT ID:', projectId);
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('MintReservation')
        .doc(mintReservationId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as MintReservation);
        });
};

export const getLiveMintRedemptionsRemaining = async (projectId: string) => {
    console.log('PROJECT ID:', projectId);
    const burns = await firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('MintRedemption')
        .where('status', '==', 'Minted')
        .get();

    const projectSnap = await firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .get();
    const project = projectSnap.data() as Project;
    const burnCount = project.burnCount || 0;
    return burnCount - (burns.docs.length || 0);
};

export const getLiveMintRedemptionListener = (
    projectId: string,
    mintRedemptionId: string,
    onNext: (liveMintRedemptions: MintReservation) => void
) => {
    console.log('MINT Redemption ID:', mintRedemptionId);
    console.log('PROJECT ID:', projectId);
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection('MintRedemption')
        .doc(mintRedemptionId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as MintReservation);
        });
};

export const getLiveMintOwnersListener = (
    projectId: string,
    onNext: (mintOwners: DropOwner[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection(collections.dropOwner)
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as DropOwner));
            },
            (err) => console.error(err)
        );
};

export const getPolicyStatsListener = (
    policy: string,
    onNext: (policyStats: NativePolicyStats) => void
) => {
    return firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as NativePolicyStats);
        });
};

export const getTTPolicyStatsListener = (
    policy: string,
    onNext: (policyStats: TTPolicyStats) => void
) => {
    return firebase
        .firestore()
        .collection(collections.TapTools)
        .doc(policy)
        .collection(collections.policyStats)
        .doc('Data')
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as TTPolicyStats);
        });
};

export const getPolicyActivityListener = (
    policy: string,
    limit: number,
    onNext: (assets: NativePolicyTransactions[]) => void
) => {
    if (!policy) {
        return;
    }
    return firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.salesActivity)
        .limit(limit)
        .orderBy('sold_at', 'desc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as NativePolicyTransactions));
            },
            (err) => console.error(err)
        );
};

export const getTTPolicyStats = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.TapTools)
        .doc(policy)
        .collection(collections.policyStats)
        .doc('Data')
        .get();

    return policyStats.data() as TTPolicyStats;
};

export const getPolicyStats = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .get();

    return policyStats.data() as NativePolicyStats;
};

export const getJPolicyDetails = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.jungle)
        .doc(policy)
        .get();

    return policyStats.data() as JunglePolicy;
};

export const getFloorHistory = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.floorHistory)
        .doc(collections.oneYear)
        .get();

    return policyStats.data() as FloorHistory;
};

export const getHolderHistory = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.holderHistory)
        .doc('ALL')
        .get();

    return policyStats.data() as HolderHistory;
};

export const getSaleHistory = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.saleHistory)
        .doc('ALL')
        .get();

    return policyStats.data() as SaleHistory;
};

export const getSalesHistoryChart = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.liveTransactions)
        .where('type', '==', 'sale')
        .orderBy('createdDate', 'desc')
        .limit(500)
        .get();

    return policyStats.docs.map((doc) => doc.data() as LiveTransaction);
};

export const getHolderDistribution = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.holderHistory)
        .doc('Summary')
        .get();

    return policyStats.data() as HolderDistribution;
};
export const getTTHolderDistribution = async (policy: string) => {
    const policyStats = await firebase
        .firestore()
        .collection(collections.TapTools)
        .doc(policy)
        .collection(collections.holderDistribution)
        .doc('Data')
        .get();

    return policyStats.data() as TTHolderDistribution;
};

export const getTraitFloors = async (policy: string) => {
    const floors = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.traitFloors)
        .doc('Data')
        .get();

    return floors.data();
};

export const getTTTraitFloors = async (policy: string) => {
    const floors = await firebase
        .firestore()
        .collection(collections.TapTools)
        .doc(policy)
        .collection(collections.traitFloors)
        .doc('Data')
        .get();

    return floors.data();
};

export const getHolders = async (policy: string, limit?: number) => {
    const holders = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.holderHistory)
        .doc('DETAIL')
        .collection('HolderDetail')
        .limit(limit || 100)
        .orderBy('totalAmount', 'desc')
        .get();

    let lastKey = '';
    holders.forEach((doc) => {
        lastKey = doc.data().holderStake as string;
    });

    return holders.docs.map((doc) => doc.data()).map((data) => data as Holder);
};

export const getHolderWithKey = async (policy: string, limit?: number) => {
    const holdersRef = await firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.holderHistory)
        .doc('DETAIL')
        .collection('HolderDetail')
        .limit(limit || 100)
        .orderBy('totalAmount', 'desc')
        .get();

    let lastKey = '';
    holdersRef.forEach((doc) => {
        lastKey = doc.data().holderStake as string;
    });
    const holders = holdersRef.docs
        .map((doc) => doc.data())
        .map((data) => data as Holder);
    return { holders, lastKey };
};

export interface HolderResult {
    holders: Holder[];
    lastKey: string;
}

export const getHoldersForNextBatch = async (
    key: string,
    policy: string,
    limit: number
): Promise<HolderResult> => {
    const holdersRef = firebase
        .firestore()
        .collection(collections.policyStats)
        .doc(policy)
        .collection(collections.holderHistory)
        .doc('DETAIL')
        .collection('HolderDetail')
        .limit(limit || 100)
        .orderBy('totalAmount', 'desc');

    const querySnap = await holdersRef.limit(limit).startAfter(key).get();

    let lastKey = '';
    querySnap.forEach((doc) => {
        lastKey = doc.data().holderStake;
    });

    const holders = querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Holder);
    return { holders, lastKey };
};

export const getMintRequestListener = (
    projectId: string,
    onNext: (mintRequest: MintRequest[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.mintRequest)
        .where('projectId', '==', projectId)
        .limit(1)
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as MintRequest));
            },
            (err) => console.error(err)
        );
};

export const markNotificationsAsSeen = (
    userId: string,
    notifications: Notification[]
) => {
    console.log('deleting notifications');

    notifications.map(async (notificationToUpdate) => {
        console.log(notificationToUpdate.id);
        // const path = `/${collections.creator}/${userId}/${collections.notification}/${notificationToUpdate.id}`;
        await firebase
            .firestore()
            .collection(collections.creator)
            .doc(userId)
            .collection(collections.notification)
            .doc(notificationToUpdate.id)
            .set({ ...notificationToUpdate, status: 'SEEN' }, { merge: true });
    });
};

export const getCurrentRaffles = (callback: (raffles?: Raffle[]) => void) => {
    console.log('In getRafflesForUser');
    const db = firebase.firestore();
    return db
        .collection(collections.raffles)
        .where('active', '==', true)
        .onSnapshot((snap) => {
            callback(snap.docs.map((doc) => doc.data() as Raffle));
        });
};

export const getRaffleNotificationsListener = (
    limit: number,
    onNext: (notificaitons: RaffleEntryNotification[]) => void
) => {
    return firebase
        .firestore()
        .collectionGroup('RaffleNotification')
        .limit(limit)
        .orderBy('creationDate', 'desc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as RaffleEntryNotification));
            },
            (err) => console.error(err)
        );
};

export const getCreatorRaffleEntries = (
    raffleId: string,
    userId: string,
    callback: (raffles?: RaffleEntry[]) => void
) => {
    console.log('In getRafflesForUser');
    const db = firebase.firestore();
    return db
        .collection(collections.raffles)
        .doc(raffleId)
        .collection('IssuedTickets')
        .where('userId', '==', userId)
        .onSnapshot((snap) => {
            callback(snap.docs.map((doc) => doc.data() as RaffleEntry));
        });
};

export const getRaffleNotificationsListenerForRaffle = (
    raffleId: string,
    limit: number,
    onNext: (notificaitons: RaffleEntryNotification[]) => void
) => {
    return firebase
        .firestore()
        .collectionGroup('RaffleNotification')
        .where('raffleId', '==', raffleId)
        .limit(limit)
        .orderBy('creationDate', 'desc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as RaffleEntryNotification));
            },
            (err) => console.error(err)
        );
};

export const getNewRaffleNotificationsListener = (
    limit: number,
    onNext: (notifications: RaffleEntryNotification[]) => void
) => {
    let lastCreationDate: number | undefined = undefined; // Track the last document's creation date

    return firebase
        .firestore()
        .collectionGroup('RaffleNotification')
        .orderBy('creationDate', 'desc')
        .limit(limit)
        .onSnapshot(
            (snapshot) => {
                if (!snapshot.empty) {
                    const newDocs: RaffleEntryNotification[] = [];

                    snapshot.docChanges().forEach((change) => {
                        if (
                            change.type === 'added' &&
                            (!lastCreationDate ||
                                change.doc.data().creationDate > lastCreationDate)
                        ) {
                            newDocs.push(change.doc.data() as RaffleEntryNotification);
                        }
                    });

                    if (newDocs.length > 0) {
                        lastCreationDate = newDocs[0].creationDate; // Update the last creation date
                        onNext(newDocs);
                    }
                }
            },
            (err) => console.error(err)
        );
};

export const getNotificationsListener = (
    userId: string,
    limit: number,
    onNext: (notificaitons: Notification[]) => void
) => {
    if (!userId) {
        return;
    }

    const path = `/Creator/${userId}/Notification`;
    console.log('PATH NOTIF:', path);
    return firebase
        .firestore()
        .collection(path)
        .limit(limit)
        .where('status', '==', 'NEW')
        .orderBy('creationDate', 'desc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as Notification));
            },
            (err) => console.error(err)
        );
};

export const getAssetsListener = (
    projectId: string,
    limit: number,
    onNext: (assets: Asset[]) => void
) => {
    if (!projectId) {
        return;
    }
    return firebase
        .firestore()
        .collection(collections.asset)
        .limit(limit)
        .where('projectId', '==', projectId)
        .orderBy('creationDate', 'asc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as Asset));
            },
            (err) => console.error(err)
        );
};

export const getAssetsToDropFirst = async (
    projectId: string,
    limit: number
) => {
    const db = firebase.firestore();
    const firstDropSet = await db
        .collection(collections.project)
        .doc(projectId)
        .collection(collections.assetsToDrop)
        .orderBy('id', 'asc')
        .limit(limit)
        .get();

    const assetsToDrop = firstDropSet.docs.map(
        (doc) => doc.data() as AssetToDrop
    );

    let lastKey: number | null = null;
    if (firstDropSet.docs.length > 0) {
        const lastDoc = firstDropSet.docs[
            firstDropSet.docs.length - 1
        ].data() as AssetToDrop;
        lastKey = lastDoc.tokenNumber;
    }

    return { assetsToDrop, lastKey };
};

export const getAssetsToDropByAttribute = async (
    projectId: string,
    attributes: string[]
) => {
    const db = firebase.firestore();
    const firstDropSet = await db
        .collection(collections.project)
        .doc(projectId)
        .collection(collections.assetsToDrop)
        .limit(200)
        .where('asset.algoliaLevel2', 'array-contains-any', attributes)
        .get();

    return firstDropSet.docs.map((doc) => doc.data() as AssetToDrop);
};

export interface AssetsToDropResult {
    assetsToDrop: AssetToDrop[];
    lastKey: number;
}

export const getAssetsToDropAfterLastKey = async (
    projectId: string,
    afterKey: number,
    limit: number
) => {
    if (!projectId) {
        return;
    }
    const db = firebase.firestore();
    const firstDropSet = await db
        .collection(collections.project)
        .doc(projectId)
        .collection(collections.assetsToDrop)
        .orderBy('id', 'asc')
        .startAfter(afterKey)
        .limit(limit)
        .get();

    let lastKey: number | null = null;
    if (firstDropSet.docs.length > 0) {
        const lastDoc = firstDropSet.docs[
            firstDropSet.docs.length - 1
        ].data() as AssetToDrop;
        lastKey = lastDoc.tokenNumber;
    }

    const assetsToDrop = firstDropSet.docs.map(
        (doc) => doc.data() as AssetToDrop
    );

    return { assetsToDrop, lastKey };
};

export const getAssetsToDropListener = (
    projectId: string,
    onNext: (assets: AssetToDrop[]) => void
) => {
    if (!projectId) {
        return;
    }
    return firebase
        .firestore()
        .collection(collections.project)
        .doc(projectId)
        .collection(collections.assetsToDrop)
        .orderBy('creationDate', 'asc')
        .limit(10)
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as AssetToDrop));
            },
            (err) => console.error(err)
        );
};

export const getListedAssetsListener = (
    projectId: string,
    limit: number,
    onNext: (assets: Asset[]) => void
) => {
    if (!projectId) {
        return;
    }
    return firebase
        .firestore()
        .collection(collections.asset)
        .limit(limit)
        .where('projectId', '==', projectId)
        .where('marketStatus', '==', 'Listed')
        .orderBy('creationDate', 'asc')
        .onSnapshot(
            (snap) => {
                if (!snap) {
                    return;
                }
                onNext(snap.docs.map((doc) => doc.data() as Asset));
            },
            (err) => console.error(err)
        );
};

export const createAsset = async (asset: Partial<Asset>) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create Invites');
    }

    const fullAsset = { ...asset };

    if (!fullAsset.creationDate) {
        fullAsset.creationDate = new Date().getTime();
        fullAsset.createdBy = currentUser.uid;
    }

    fullAsset.lastUpdateDate = new Date().getTime();
    fullAsset.lastUpdatedBy = currentUser.uid;

    await firebase
        .firestore()
        .collection(collections.asset)
        .doc(fullAsset.id)
        .set(fullAsset, { merge: true });

    return fullAsset;
};

export const switchProjectPresale = async (
    project: Project,
    active: boolean
) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create project sale phases');
    }
    const db = firebase.firestore();

    await db
        .collection(collections.project)
        .doc(project.id)
        .collection('SalePhases')
        .doc('Preasle')
        .set({ active: active }, { merge: true });
    return;
};

export const switchProjectWhiteList = async (
    project: Project,
    active: boolean
) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create project sale phases');
    }
    const db = firebase.firestore();

    await db
        .collection(collections.project)
        .doc(project.id)
        .collection('SalePhases')
        .doc('Whitelist')
        .set({ active: active }, { merge: true });
    return;
};

export const switchProjectReservePool = async (
    project: Project,
    active: boolean
) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create project sale phases');
    }
    const db = firebase.firestore();

    await db
        .collection(collections.project)
        .doc(project.id)
        .collection('SalePhases')
        .doc('ReservePool')
        .set({ active: active }, { merge: true });
    return;
};

export const createLikeForAsset = async (asset: Asset) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create likes');
    }

    const db = firebase.firestore();

    // first look for a like:

    const like = await db
        .collection(collections.asset)
        .doc(asset.id)
        .collection(collections.likes)
        .doc(currentUser.uid)
        .get();

    if (like.exists) {
        await like.ref.delete();
        return 'R';
    }

    const ai: AssetLike = {
        userId: currentUser.uid,
        displayName: currentUser.displayName || '',
        photoURL: currentUser.photoURL || '',
        assetId: asset.id,
        asset,
    };

    await db
        .collection(collections.asset)
        .doc(asset.id)
        .collection(collections.likes)
        .doc(currentUser.uid)
        .set(ai, { merge: true });
    return 'A';
};

export const getLikesForUser = (
    uid: string,
    callback: (likes?: AssetLike[]) => void
) => {
    console.log('In getLikesForUser', uid);
    const db = firebase.firestore();
    return db
        .collectionGroup(collections.likes)
        .where('userId', '==', uid)
        .onSnapshot((snap) => {
            callback(snap.docs.map((doc) => doc.data() as AssetLike));
        });
};

export const getLicenceForAsset = (callback: (license: License) => void) => {
    const db = firebase.firestore();
    return db
        .collection(collections.license)
        .orderBy('creationDate', 'desc')
        .onSnapshot((snap) => {
            console.log('We have license details yay. ');

            callback(snap.docs[0].data() as License);
        });
};

export const createInvite = async (invite: InviteCapture) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot create Invites');
    }

    const fullInvite: Invite = {
        ...invite,
        createdBy: currentUser.uid,
        creationDate: new Date().getTime(),
        lastUpdatedBy: currentUser.uid,
        lastUpdateDate: new Date().getTime(),
    };

    const db = firebase.firestore();

    return db.collection(collections.invite).doc(fullInvite.id).set(fullInvite);
};

export const revokeInvite = (inviteId: string) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users read Invites');
    }

    const db = firebase.firestore();

    return db.collection(collections.invite).doc(inviteId).set(
        {
            userId: 'Revoked',
        },
        { merge: true }
    );
};

export const deleteInvite = (inviteId: string) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users read Invites');
    }

    const db = firebase.firestore();

    return db.collection(collections.invite).doc(inviteId).delete();
};

export const createTVConnect = async (code: number) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot Create Creators');
    }

    const db = firebase.firestore();

    const newID = v4();

    return db
        .collection(collections.creator)
        .doc(currentUser.uid)
        .collection(collections.tvConnect)
        .doc(newID)
        .set(
            {
                connectCode: code,
                uid: currentUser.uid,
                createdDate: 0,
                status: 'PENDING',
                id: newID,
            },
            { merge: true }
        );
};

export const getTVConnectListener = (
    uid: string,
    onNext: (tvConnections: TVConnect[]) => void
) => {
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(uid)
        .collection(collections.tvConnect)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.docs.map((doc) => doc.data() as TVConnect));
        });
};

export const deleteTVConnection = (uid: string, connectionId: string) => {
    const db = firebase.firestore();

    return db
        .collection(collections.creator)
        .doc(uid)
        .collection(collections.tvConnect)
        .doc(connectionId)
        .delete();
};

export const getInvites = (callback: (invites: Invite[]) => void) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users read Invites');
    }

    const db = firebase.firestore();
    return db
        .collection(collections.invite)
        .orderBy('creationDate', 'desc')
        .onSnapshot((snap) => {
            callback(snap.docs.map((doc) => doc.data() as Invite));
        });
};

export const getCreatorInvites = (callback: (invites: Invite[]) => void) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users read Invites');
    }

    const db = firebase.firestore();
    return (
        db
            .collection(collections.invite)
            //  .where('createdById', '==', currentUser.uid)
            .onSnapshot((snap) => {
                callback(snap.docs.map((doc) => doc.data() as Invite));
            })
    );
};

export const createCreator = async (
    creator: Partial<Creator>,
    merge = false
) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot Create Creators');
    }

    const db = firebase.firestore();

    if (!creator.creationDate) {
        creator.creationDate = new Date().getTime();
        creator.createdBy = currentUser.uid;
        creator.createdByAction = 'ADMIN';
    }

    creator.lastUpdateDate = new Date().getTime();
    creator.lastUpdatedBy = currentUser.uid;

    return db
        .collection(collections.creator)
        .doc(creator.id)
        .set(creator, { merge });
};

export const getCreatorsListener = (
    onNext: (creators: Creator[]) => void
): (() => void) => {
    const db = firebase.firestore();

    return db
        .collection(collections.creator)
        .orderBy('creationDate', 'desc')
        .onSnapshot((snap) =>
            onNext(snap.docs.map((doc) => doc.data()).map((data) => data as Creator))
        );
};

export const getCreators = async (limit?: number): Promise<Creator[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.creator)
        .orderBy('creationDate', 'desc')
        .limit(limit || 100)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Creator);
};

export const getCreator: (creator: string) => Promise<Creator> = async (
    creatorId: string
): Promise<Creator> => {
    const db = firebase.firestore();

    const result = await db.collection(collections.creator).doc(creatorId).get();

    return result.data() as Creator;
};

export const getAllCreators = async (): Promise<Creator[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.creator)
        .orderBy('creationDate', 'desc')
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Creator);
};

export const getCreatorById = async (id: string) => {
    const db = firebase.firestore();

    const querySnap = await db.collection(collections.creator).doc(id).get();

    return querySnap.data() as Creator;
};

export const getCreatorByDisplayName = async (
    displayName: string
): Promise<Creator[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.creator)
        .orderBy('creationDate', 'desc')
        .where('displayName', '==', displayName)
        .limit(1)
        .get();

    return querySnap.docs.map((doc) => doc.data()).map((data) => data as Creator);
};

export const getCreatorListener = (
    creatorId: string,
    onNext: (creator: Creator) => void
) => {
    return firebase
        .firestore()
        .collection(collections.creator)
        .doc(creatorId)
        .onSnapshot((snap) => {
            if (!snap) {
                return;
            }
            onNext(snap.data() as Creator);
        });
};

export const getActivityFeed = async (limit?: number): Promise<Activity[]> => {
    const db = firebase.firestore();

    const querySnap = await db
        .collection(collections.activity)
        //.orderBy('creationDate', 'desc')
        .limit(limit || 100)
        .get();

    return querySnap.docs
        .map((doc) => doc.data())
        .map((data) => data as Activity);
};

export const getMintRequestForPolicy = async (policy: string) => {
    const db = firebase.firestore();
    const mintRequests = await db
        .collection(collections.mintRequest)
        .where('policy', '==', policy)
        .get();

    if (mintRequests.empty) {
        throw new Error(`No Mint Requests found for this Policy (${policy})`);
    }

    return mintRequests.docs.map((doc) => doc.data() as MintRequest);
};

export const getOfferMetadata = async (hash: string) => {
    const db = firebase.firestore();
    const doc = await db.collection(collections.offerMetadata).doc(hash).get();

    if (!doc.exists) {
        throw new Error('DATUM_HASH_NOT_FOUND');
    }

    return doc.data() as Record<number | '_uid', string> &
        Record<'_metadata', OFFER_DATUM_TYPE>;
};

export const updateAssetListing = async (
    assetId: string,
    price: number | null,
    txHash: string,
    marketStatus: 'LISTED' | 'UNLISTED'
) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot Create Offer Metadata');
    }

    const db = firebase.firestore();

    const assetUpdates: { [key: string]: string | number } = {
        txHash,
        marketStatus,
    };

    if (price) assetUpdates.price = price;

    try {
        return db
            .collection(collections.asset)
            .doc(assetId)
            .set(assetUpdates, { merge: true });
    } catch (err) {
        console.error(err);
    }
};

export const saveOfferMetadata = async (
    id: string,
    metadata: Record<number, string> & Record<'_metadata', OFFER_DATUM_TYPE>
): Promise<void> => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
        throw new Error('Unauthenticated users cannot Create Offer Metadata');
    }

    const db = firebase.firestore();

    return db
        .collection(collections.offerMetadata)
        .doc(id)
        .set({
            ...metadata,
            _metadata: {
                ...metadata._metadata,
                ownerAddress: Buffer.from(metadata._metadata.ownerAddress).toString(
                    'hex'
                ),
            },
            _uid: currentUser.uid,
        });
};
