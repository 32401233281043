import firebase from 'firebase/app';

/**
 * useWalletRefresh hook is used to trigger the PubSub process used to refresh the wallet data.
 */

import { Address } from '@hyperionbt/helios';
import { useCallback, useEffect } from 'react';
import { useFirebaseCurrentUser } from '../hooks/useFirebaseCurrentUser';
import { useCardanoWallet } from './WalletContext';

export function useWalletRefresh() {
  const { currentUser } = useFirebaseCurrentUser();
  const { wallet } = useCardanoWallet();

  const ping = useCallback(async () => {
    if (!wallet) return;

    const paymentAddresses = (
      await Promise.all([
        wallet.getUsedAddresses(),
        wallet.getUnusedAddresses(),
      ])
    ).flatMap((a) => a.map((addrHex) => Address.fromHex(addrHex).toBech32()));

    if (currentUser?.uid) {
      const attr = JSON.stringify({
        walletAddress: currentUser?.uid as string,
        paymentAddresses: JSON.stringify(paymentAddresses),
      });

      console.log('useWalletRefresh', JSON.stringify(attr));

      firebase.functions().httpsCallable('callPubSubTopic')({
        topic: 'wallet-refresh',
        attributes: attr,
      });
      firebase.functions().httpsCallable('callPubSubTopic')({
        topic: 'listing-refresh',
        attributes: attr,
      });
      console.log(
        'use effect fired for Stats call status: ',
        ' for ',
        currentUser.uid
      );
    }
  }, [currentUser?.uid, wallet]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    ping().catch(console.error);
  }, [currentUser, ping]);

  return { ping };
}
