import { firebaseConfig } from './firebase';
import { init } from 'next-firebase-auth';
import constants from './constants';

export const databaseURL = constants.NEXT_PUBLIC_FIREBASE_DB_URL || 'NOT_SET';
export const config = {
  authPageURL: '/login',
  appPageURL: '/',
  loginAPIEndpoint: '/api/login',
  logoutAPIEndpoint: '/api/logout',
  cookies: {
    name: 'next-test',
    sameSite: 'strict',
    signed: false,
    secure: false,
  },
  firebaseClientInitConfig: {
    projectId: firebaseConfig.projectId,
    authDomain: firebaseConfig.authDomain,
    apiKey: firebaseConfig.apiKey,
    databaseURL: databaseURL,
  },
  firebaseAdminInitConfig: {
    credential: {
      clientEmail: process.env.FIREBASE_CLIENT_EMAIL || 'NOT SET',
      privateKey: process.env.FIREBASE_PRIVATE_KEY || '',
      projectId: firebaseConfig.projectId,
    },
    databaseURL: databaseURL,
  },
};

export const initAuth = () => {
  init(config);
};
