// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
console.log('Debugging', process.env.NEXT_PUBLIC_DEBUG);
Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://73cf0a769b0940b8a94f1bbf3e8e4235@o955533.ingest.sentry.io/5904848',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  enabled: !process.env.NEXT_PUBLIC_DEBUG,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'PROD',
  // tunnel: '/api/s/t',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
