import firebase from 'firebase/app';
import 'firebase/firestore';
import constants from './constants';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: constants.NEXT_PUBLIC_FIREBASE_API_KEY || 'NOT_SET',
  authDomain: `${constants.NEXT_PUBLIC_PROJECT_ID}.firebaseapp.com`,
  storageBucket: `${constants.NEXT_PUBLIC_PROJECT_ID}.appspot.com`,
  projectId: constants.NEXT_PUBLIC_PROJECT_ID || 'NOT_SET',
  messagingSenderId: constants.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: constants.NEXT_PUBLIC_APP_ID,
  measurementId:
    constants.NEXT_PUBLIC_PROJECT_ID === 'dropspot-dev-740f7'
      ? 'G-LPES53X66R'
      : 'G-D69PGP64KY',
};

if (!firebase.apps.length) {
  console.log('Init Firebase', firebaseConfig.projectId);
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({
    ignoreUndefinedProperties: true,
    merge: true,
  });
}

const analytics = firebase.analytics;
//const analytics = firebase.analytics();
export { analytics };
export default firebase;

export type AuthStateChangeListener = (user: firebase.User | null) => void;
