/**
 * File to hold all the Public Constants used in the application
 */
const IS_TESTNET = process.env.NEXT_PUBLIC_BLOCKFROST_API_TESTNET === '1';

type ConstantsType = {
  NEXT_PUBLIC_PROJECT_ID: string;
  NEXT_PUBLIC_FIREBASE_API_KEY: string;
  NEXT_PUBLIC_FIREBASE_DB_URL: string;
  NEXT_PUBLIC_APP_ID: string;
  NEXT_PUBLIC_MESSAGING_SENDER_ID: string;
  NEXT_PUBLIC_DROPSPOT_ADDRESS: string;
  NEXT_PUBLIC_DROPSPOT_CONTRACT_ADDRESS: string;
  NEXT_PUBLIC_GOOGLE_ANALYTICS: string;
  NEXT_PUBLIC_INSTA_CLIENT_ID: string;
  NEXT_PUBLIC_IPINFO: string;
  TESTNET: boolean;
  ALGOLIA_ASSET_INDEX: string;
  ALGOLIA_ASSET_OLDEST_FIRST_INDEX: string;
  ALGOLIA_CREATOR_INDEX: string;
  ALGOLIA_PROJECT_INDEX: string;
  ALGOLIA_PROJECT_INDEX_OLDEST_FIRST: string;
  ALGOLIA_PROJECT_INDEX_ALPHA: string;
  ALGOLIA_PROJECT_INDEX_VOLUME: string;
  ALGOLIA_PROJECT_INDEX_MINT_OLD_FIRST: string;
  ALGOLIA_PROJECT_INDEX_MINT_NEW_FIRST: string;
  VIDEO_THUMBNAIL: string;
  CLAIM_PUBSUB_TOPIC: string;
  PUSHER_APP_KEY: string;
  PUSHER_APP_CLUSTER: string;
  IMG_BASE_URL: string;
  NETWORK_PARAMS_URL: string;
  HOLDING_PAGE_ON: boolean;
  CLOUD_MESSAGING_KEY: string;
  SUPER_CUBE_POLICY: string;
  /* Wallet where we pay for SuperCubes */
  SUPER_CUBE_WALLET: string;
  OFFER_VALIDATOR_HASH: string[];
  NEXT_PUBLIC_JPG_CF_WORKER: string;
};

let constants: ConstantsType;
if (!IS_TESTNET) {
  console.log('====================================');
  console.log('Running in Mainnet', IS_TESTNET);
  console.log('====================================');
  constants = {
    OFFER_VALIDATOR_HASH: [
      '5843eccf979a50d66eb0c4e710bd1c250b224a1fc17f99ca51ac2c3f',
      '9BE1CCB2356091BA62DC3EF7AEE364BE3B482125BAF9EB60065337A4',
    ],
    SUPER_CUBE_POLICY:
      'f291066d3946765df78e50a36875295fc0e88a9db13535f6d737056e',
    NETWORK_PARAMS_URL: 'https://d1t0d7c2nekuk0.cloudfront.net/mainnet.json',
    IMG_BASE_URL: 'https://media.dropspot.io',
    HOLDING_PAGE_ON: false,
    PUSHER_APP_CLUSTER: 'ap1',
    PUSHER_APP_KEY: '9305d30c59af155b2460',
    CLOUD_MESSAGING_KEY:
      'BInt6idObzVQNZR8NU7j_IJfdb_4pa2uiStwgYNay7IsepuBb2_SUaCMkC9eiJY3ep1iVedpyq0AEEzI8PZXil0',
    TESTNET: false,
    CLAIM_PUBSUB_TOPIC: 'Dropspot-Claim',
    NEXT_PUBLIC_FIREBASE_API_KEY: 'AIzaSyBuxmyfWRL79hl4JhXV0xN4_TfXMfvi3zA',
    NEXT_PUBLIC_PROJECT_ID: 'dropspot-b684f',
    // NEXT_PUBLIC_PLUTUS_CONTRACT_ADDRESS:
    //   'addr_test1wzghrmpcktps4dhmkmtwfvfg9jvpz2tkhyfak0edzwttypgrp33zg', //'addr_test1wq3yyneks7s5p2f8s4gywrh4xvtz472dq7styy8azf5vkqsul8823',
    NEXT_PUBLIC_MESSAGING_SENDER_ID: '406916423820',
    NEXT_PUBLIC_INSTA_CLIENT_ID: '614007359860864',
    NEXT_PUBLIC_GOOGLE_ANALYTICS: 'G-RC2DYHCHTH',
    NEXT_PUBLIC_FIREBASE_DB_URL:
      'https://dropspot-b684f.australia-southeast1.firebasedatabase.app',
    SUPER_CUBE_WALLET:
      'addr1qxr3gyevsdnnqdczhl4vh0sxh685h9zzuq83mhg99y68j3cj3fjrevh4a4pt4sqm2lsc0avsrezlucp3306a5t7zg0eqvd4upw',
    NEXT_PUBLIC_DROPSPOT_ADDRESS:
      'addr1qxr3gyevsdnnqdczhl4vh0sxh685h9zzuq83mhg99y68j3cj3fjrevh4a4pt4sqm2lsc0avsrezlucp3306a5t7zg0eqvd4upw',
    NEXT_PUBLIC_DROPSPOT_CONTRACT_ADDRESS:
      'addr1zyfk6gn0cp5cmjj4l0dwjp7sf5lgnh7vhy2ttnqgkauu08cj3fjrevh4a4pt4sqm2lsc0avsrezlucp3306a5t7zg0eqtd4xj3',
    NEXT_PUBLIC_APP_ID: '1:406916423820:web:24bb0058578c23225f5ba9',
    NEXT_PUBLIC_IPINFO: 'fec700b1ab3bf5',
    ALGOLIA_ASSET_INDEX: 'TS_ASSET',
    ALGOLIA_ASSET_OLDEST_FIRST_INDEX: 'ASSET_OLDEST_FIRST',
    ALGOLIA_CREATOR_INDEX: 'CREATOR',
    ALGOLIA_PROJECT_INDEX: 'Collection',
    ALGOLIA_PROJECT_INDEX_OLDEST_FIRST: 'CollectionOldestFirst',
    ALGOLIA_PROJECT_INDEX_ALPHA: 'CollectionAlphabetical',
    ALGOLIA_PROJECT_INDEX_VOLUME: 'CollectionVolume',
    ALGOLIA_PROJECT_INDEX_MINT_OLD_FIRST: 'CollectionMintOld',
    ALGOLIA_PROJECT_INDEX_MINT_NEW_FIRST: 'CollectionMintNew',
    VIDEO_THUMBNAIL:
      'https://firebasestorage.googleapis.com/v0/b/dropspot-b684f.appspot.com/o/web-assets%2Fthumbnail-placeholder.png?alt=media&token=8525df6f-33ea-41b3-adb0-24c9d705bb81',
    NEXT_PUBLIC_JPG_CF_WORKER:
      'https://jpg-tx-builder-production.ds-god-m-fn.workers.dev',
  };
} else {
  console.log('====================================');
  console.log('Running in Testnet', IS_TESTNET);
  console.log('====================================');
  constants = {
    OFFER_VALIDATOR_HASH: [
      '1ccad2f512eb89b7431127fa5c18944d12b62d43ed8857961ca8c8e7',
      'ed80e3ccbc8fa98272a5c9d9e3546582330884d1105b30f0172d0e67',
    ],
    SUPER_CUBE_WALLET:
      'addr_test1qqjd0qg7h4a079lxhe9j773kw7yxl9shjulprxz4mgpnhww6hlww9sc4lmdsne9688dugdzcvcjhamjrmyczu0jtgqfsl7gefx',
    SUPER_CUBE_POLICY:
      '3c0d3fc7d1f64b75b383166ff74a92a7da8b7606514181243f2f6335',
    NETWORK_PARAMS_URL: 'https://d1t0d7c2nekuk0.cloudfront.net/preprod.json',
    IMG_BASE_URL: 'https://img-tn.dropspot.io',
    PUSHER_APP_CLUSTER: 'ap1',
    HOLDING_PAGE_ON: false,
    CLOUD_MESSAGING_KEY: '',
    PUSHER_APP_KEY: 'da426e89285e1f34908b',
    TESTNET: true,
    CLAIM_PUBSUB_TOPIC: 'Dropspot-Claim',
    NEXT_PUBLIC_PROJECT_ID: 'dropspot-dev-740f7',
    NEXT_PUBLIC_FIREBASE_API_KEY: 'AIzaSyCxs1vrxssRllkVzuQSeY6g6_ol12WqrT4',
    NEXT_PUBLIC_FIREBASE_DB_URL:
      'https://dropspot-dev-740f7.australia-southeast1.firebasedatabase.app',
    NEXT_PUBLIC_APP_ID: '1:260248637028:web:159fc921dc801b7eb51971',
    NEXT_PUBLIC_MESSAGING_SENDER_ID: '260248637028',
    NEXT_PUBLIC_DROPSPOT_ADDRESS:
      'addr_test1qqjd0qg7h4a079lxhe9j773kw7yxl9shjulprxz4mgpnhww6hlww9sc4lmdsne9688dugdzcvcjhamjrmyczu0jtgqfsl7gefx',
    NEXT_PUBLIC_DROPSPOT_CONTRACT_ADDRESS:
      'addr_test1qqjd0qg7h4a079lxhe9j773kw7yxl9shjulprxz4mgpnhww6hlww9sc4lmdsne9688dugdzcvcjhamjrmyczu0jtgqfsl7gefx',
    NEXT_PUBLIC_GOOGLE_ANALYTICS: 'G-RC2DYHCHTH',
    NEXT_PUBLIC_INSTA_CLIENT_ID: '614007359860864',
    NEXT_PUBLIC_IPINFO: 'fec700b1ab3bf5',
    // NEXT_PUBLIC_PLUTUS_CONTRACT_ADDRESS:
    //   'addr_test1wq3yyneks7s5p2f8s4gywrh4xvtz472dq7styy8azf5vkqsul8823',
    ALGOLIA_ASSET_INDEX: 'DEV_ASSET',
    ALGOLIA_ASSET_OLDEST_FIRST_INDEX: 'DEV_ASSET_OLDEST_FIRST',
    ALGOLIA_CREATOR_INDEX: 'DEV_CREATOR',
    ALGOLIA_PROJECT_INDEX: 'DEV_PROJECT',
    ALGOLIA_PROJECT_INDEX_OLDEST_FIRST: 'DEV_PROJECT_OLDEST_FIRST',
    ALGOLIA_PROJECT_INDEX_ALPHA: 'CollectionAlphabetical',
    ALGOLIA_PROJECT_INDEX_VOLUME: 'CollectionVolume',
    ALGOLIA_PROJECT_INDEX_MINT_OLD_FIRST: 'CollectionMintOld',
    ALGOLIA_PROJECT_INDEX_MINT_NEW_FIRST: 'CollectionMintNew',
    VIDEO_THUMBNAIL:
      'https://firebasestorage.googleapis.com/v0/b/dropspot-b684f.appspot.com/o/web-assets%2Fthumbnail-placeholder.png?alt=media&token=8525df6f-33ea-41b3-adb0-24c9d705bb81',
    NEXT_PUBLIC_JPG_CF_WORKER:
      'https://jpg-tx-builder-dev.ds-god-m-fn.workers.dev',
  };
}

Object.freeze(constants);

export default constants;
