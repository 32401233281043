import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { VFC, Fragment } from 'react';
import cn from 'classnames';

export interface AlertProps {
  message: string;
  title: string;
  type: 'SUCCESS' | 'ERROR' | 'QUESTION';
  className?: string;
  open: boolean;
  errorId?: string;
  button1: {
    title: string;
    onClick: () => void;
  };
  button2?: {
    title: string;
    onClick: () => void;
  };
  hideIcon?: boolean;
  onClose: () => void;
}

export function Alert({
  title,
  message,
  button1,
  button2,
  hideIcon,
  open,
  onClose,
  type,
  errorId,
}: AlertProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-40 inset-0 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 -translate-y-10 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <span>
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl " />
              <AlertBox
                title={title}
                message={message}
                button1={button1}
                button2={button2}
                onClose={onClose}
                type={type}
                errorId={errorId}
                open={open}
                hideIcon={hideIcon}
              />
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export const AlertBox: VFC<AlertProps> = ({
  button1,
  message,
  onClose,
  title,
  type,
  button2,
  errorId,
  hideIcon,
}) => {
  return (
    <div className="inline-block align-bottom bg-white rounded-lg px-12 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-12">
      <div>
        {!hideIcon && (
          <div
            className={cn(
              'mx-auto flex items-center justify-center h-12 w-12 rounded-full',
              {
                'bg-green-100': type === 'SUCCESS',
              },
              { 'bg-red-100': type === 'ERROR' },
              { 'bg-yellow-100': type === 'QUESTION' }
            )}
          >
            {type === 'SUCCESS' && (
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            )}
            {type === 'ERROR' && (
              <ExclamationCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            )}
            {type === 'QUESTION' && (
              <QuestionMarkCircleIcon
                className="h-6 w-6 text-yellow-600"
                aria-hidden="true"
              />
            )}
          </div>
        )}

        <div className=" text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-3xl leading-6 font-medium text-gray-900 my-2"
          >
            {title}
          </Dialog.Title>
          <div className="mt-4">
            <p className="text-lg text-gray-700">{message}</p>
            {errorId && (
              <p className="text-xs text-gray-400 mt-2">ID: {errorId}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-8 flex flex-col gap-4">
        <button
          type="button"
          className="flex-grow mx-4 my-4 inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-700 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
          onClick={() => {
            onClose();
            button1.onClick();
          }}
        >
          {button1.title}
        </button>
        {button2 && (
          <button
            type="button"
            className="flex-grow mx-4 inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
            onClick={() => {
              onClose();
              button2.onClick();
            }}
          >
            {button2.title}
          </button>
        )}
      </div>
    </div>
  );
};
