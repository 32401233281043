import '../styles/globals.css';
import '../styles/privacy.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';
import '#lib/pintura/pintura/pintura.css';
import 'rc-tooltip/assets/bootstrap.css';
import { InstallGTag, pageview } from '#lib/ga/index';
import type { AppProps } from 'next/app';
import { initAuth } from '../lib/initFirebase'; // the module you created above
import { AlertWrapper } from '#lib/utils/Alert';
import { NotifierContextProvider } from 'react-headless-notifier';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { LikesContextWrapper } from '#lib/utils/LikesContext';
import Head from 'next/head';
import type { AppRouter } from '../pages/api/trpc/[trpc]';
import { createReactQueryHooks } from '@trpc/react';
import { withTRPC } from '@trpc/next';
import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';
import { ThemeProvider } from 'next-themes';
export const trpc = createReactQueryHooks<AppRouter>();
import '../lib/wdyr';
import { useWalletRefresh } from '../lib/wallet/useWalletRefresh';
import { analytics } from '#lib/firebase';
import { trpcConfig } from '../lib/trpc';

initAuth();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      console.log('effect fired');
      messaging.onMessage((payload) => {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> Message received. ', payload);
        // ...
      });
    }
  }, []);

  useEffect(() => {
    analytics();
  }, []);

  useWalletRefresh();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <InstallGTag>
      <Head>
        <meta name="build-id" content={`ID-${process.env.BUILD_ID}`} />
        <meta name="build-date" content={process.env.BUILD_DATE} />
        <meta name="build-env" content={process.env.NEXT_PUBLIC_ENVIRONMENT} />
        <meta
          name="node-env"
          content={constants.TESTNET ? 'Preprod' : 'Mainnet'}
        />
      </Head>
      <ThemeProvider attribute="class">
        <NotifierContextProvider
          // All props are optional, those are the values by default
          config={{
            max: null, // Max number of notiication simultaneously, `null` will result in no maximum
            duration: 5000, // Duration by notification in milliseconds
            position: 'topRight', // Default position for all the notification if it's not specify when using `notify()`, valid positions are 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
          }}
        >
          <LikesContextWrapper>
            <AlertWrapper>
              <Component {...pageProps} />
            </AlertWrapper>
          </LikesContextWrapper>
        </NotifierContextProvider>
      </ThemeProvider>
    </InstallGTag>
  );
}

import { trpcClient as mainTrpcClient } from '../lib/trpc';
import constants from '../lib/constants';
export const trpcClient = mainTrpcClient;

export default withTRPC<AppRouter>({
  config() {
    return trpcConfig;
  },
  ssr: false,
})(MyApp);
