import firebase from '#lib/firebase';
import type { AuthStateChangeListener } from '#lib/firebase';
import { useCallback, useState } from 'react';
import { useEffect, useRef } from 'react';

export function useFirebaseCurrentUser() {
  const [currentUser, setCurrentUser] = useState(firebase.auth().currentUser);
  const [intercomHash, setIntercomHash] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [claims, setClaims] = useState<{ [key: string]: any }>();
  const [loading, setLoading] = useState(true);

  useEffect(
    () => firebase.auth().onAuthStateChanged((user) => setCurrentUser(user)),
    []
  );

  /**
   * Function addAuthStateChangeListener: Allows components to listen to the AuthState Changes directly.
   * Components *MUST* ensure that they remove the listener when they are unmounted.
   * For example:
   * ```
   * useEffect(() => addAuthStateChangeListener(listener), []);
   * ```
   */
  const addAuthStateChangeListener = useCallback(
    (listener: AuthStateChangeListener) =>
      firebase.auth().onAuthStateChanged(listener),
    []
  );

  const [retry, setRetry] = useState<number>(0);

  useEffect(() => {
    if (!currentUser) {
      if (retry > 0) {
        setLoading(false);
        signOut();
        return;
      }
      const timeout = setTimeout(() => setRetry(Date.now()), 1500);

      return () => clearTimeout(timeout);
    }
    setLoading(true);
    currentUser
      .getIdTokenResult(true)
      .then((result) => {
        setClaims(result.claims);
      })
      .finally(() => setLoading(false));
    const selectedWallet = localStorage.getItem('selected-wallet');
    if (!selectedWallet) {
      console.log('No selected wallet');
      signOut();
    }
  }, [currentUser, retry]);

  const refreshClaims = useCallback(
    () =>
      currentUser &&
      currentUser
        .getIdTokenResult(true)
        .then((result) => {
          setClaims(result.claims);
        })
        .finally(() => setLoading(false)),
    [currentUser]
  );

  const signOut = useCallback(async () => {
    if (!currentUser) return;
    await firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem('selected-wallet');
      });
  }, [currentUser]);

  const reloadUser = useCallback(async () => {
    if (!currentUser) return;
    await currentUser.reload();
    setCurrentUser(firebase.auth().currentUser);
  }, [currentUser]);

  // useEffect(() => {
  //   // pages/api/intercom/index.ts
  //   if (!currentUser) return;

  //   const url = getAbsoluteURL('/api/intercom');

  //   currentUser
  //     .getIdToken(true)
  //     .then((token) => {
  //       if (!token) {
  //         throw new Error('Intercom Hash: No token');
  //       }
  //       return fetch(url, {
  //         headers: {
  //           Authorization: `bearer ${token}`,
  //         },
  //       });
  //     })
  //     .then((r) => r.json())
  //     .then((d) => setIntercomHash(d.hash))
  //     .catch(() => null);
  // }, [currentUser]);

  return {
    currentUser,
    claims,
    loading,
    refreshClaims,
    intercomHash,
    reloadUser,
    signOut,
    addAuthStateChangeListener,
  };
}
