import Script from 'next/script';
import constants from '../constants';

export const pageview = (url) => {
  window.gtag('config', constants.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, params }) => {
  window.gtag('event', action, params);
};

export const InstallGTag = ({ children }) => {
  return (
    <>
      <Script
        strategy="beforeInteractive"
        id="gtag-main"
        src={`https://www.googletagmanager.com/gtag/js?id=${constants.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script strategy="afterInteractive" id="gtag1">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${constants.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
        });
      `}
      </Script>
      {children}
    </>
  );
};
