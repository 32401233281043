import {
  Address,
  CborData,
  hexToBytes,
  IntData,
  ListData,
  StakeAddress,
} from '@hyperionbt/helios';
import { useWalletStore } from './WalletContext';
import constants from '#lib/constants';
import { SUPPORTED_WALLETS_META } from '#types/cardano';

export class BalanceListener {
  private static me: BalanceListener | null = null;
  private interval: NodeJS.Timer | undefined;
  private constructor() {
    if (typeof window === 'object') {
      this.interval = setInterval(() => this.walletBalanceProcessor(), 3000);
    }
  }

  private walletBalanceProcessor = () => {
    // console.log('In walletBalanceProcessor', performance.now());
    const {
      balanceListeners,
      wallet,
      walletAddress,
      adaBalance,
      triggerSignOut,
      connecting,
    } = useWalletStore.getState();

    if (!wallet) {
      return;
    }
    if (balanceListeners.size === 0) return;
    try {
      wallet.getNetworkId().then((id) => {
        if (
          (id !== 0 && constants.TESTNET) ||
          (id !== 1 && !constants.TESTNET)
        ) {
          console.log(
            'In walletBalanceProcessor: wrong network',
            id,
            constants.TESTNET
          );
          triggerSignOut();
        }
      });
      //try.name and .internalName
      const supportedWallet = SUPPORTED_WALLETS_META.find(
        (w) => w.name === wallet.name || w.internalName === wallet.name
      );
      (!connecting && supportedWallet?.addressType === 'REWARD'
        ? wallet.getRewardAddresses()
        : wallet.getUsedAddresses()
      ).then((addr) => {
        const address =
          supportedWallet?.addressType === 'REWARD'
            ? StakeAddress.fromHex(addr[0]).toBech32()
            : Address.fromHex(addr[0]).toBech32();
        if (address && address !== walletAddress) {
          window.location.reload();
        }
      });
      wallet
        .getBalance()
        .then((balance) => {
          try {
            const bytes = hexToBytes(balance);

            let value: BigInt;

            if (CborData.isList(bytes)) {
              const l = ListData.fromCbor(bytes);
              value = (l.list[0] as IntData).int;
            } else {
              value = CborData.decodeInteger(bytes);
            }

            const newBalance = Intl.NumberFormat().format(
              Math.round(Number(value) / 10_000) / 100
            );

            if (newBalance !== adaBalance) {
              console.log(
                'In walletBalanceProcessor: newBalance !== adaBalance'
              );
              useWalletStore.setState((s) => ({
                ...s,
                adaBalance: newBalance,
              }));
            }
          } catch (err) {
            console.error('Error in inner try block:', err);
          }
        })
        .catch((err) => {
          console.log('In walletBalanceProcessor: catch block');
          console.error('Error in wallet.getBalance():', err,);
          if (err.message === 'account changed') {
            triggerSignOut();
          }
        });
    } catch (err) {
      console.error('Error in outer try block:', err);
    }
  };
  static getInstance() {
    if (!BalanceListener.me) {
      BalanceListener.me = new BalanceListener();
    }

    return BalanceListener.me;
  }
}
